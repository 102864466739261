import window from "inputmask/lib/global/window";

let is_mobile = (window.outerWidth < 768);
window.addEventListener('resize', function () {
    is_mobile = (window.outerWidth < 768);
});

document.addEventListener('DOMContentLoaded', function () {
    var choose_period = document.querySelector(".choose_period");
    if (choose_period) {
        choose_period.addEventListener('click', function (e) {

            let calendar_table_body = document.querySelector(".calendar_table_body");
            calendar_table_body.innerHTML = '';
            if (is_mobile) {
                let y = new Date().getFullYear();
                renderMobileCalendar(y);
                calendar_table_body = document.querySelector(".calendar_wrapper");
                calendar_table_body.scrollTop = 9 * 100 * 100;
            } else {
                let _month = document.querySelector(".calendar_dropdown .calendar_month_selected");
                let _year = document.querySelector(".calendar_dropdown .calendar_year_selected");
                renderCalendar(_month.dataset.index, _year.dataset.index, _year.dataset.index + '_' + _month.dataset.index, true);
            }

        });
    }
    let date_filter = document.querySelectorAll('.date_filter li');
    if (date_filter) {
        for (let i = 0; i < date_filter.length; i++) {
            date_filter[i].addEventListener('click', function (e) {
                switch (e.target.closest('li').dataset.period) {
                    case 'w':
                        quickFilterCalendar(7);
                        break;
                    case 'm':
                        quickFilterCalendar(30);
                        break;
                    case 'y':
                        quickFilterCalendar(365);
                        break;
                    case 'q':
                        quickFilterCalendar(90);
                        break;
                    case 'all':
                        quickFilterCalendar(999);
                        break;
                }
            });
        }
    }

});

let flag = true;
if (document.querySelector(".calendar_dropdown")) {
    if (is_mobile) {

        let y = new Date().getFullYear();
        renderMobileCalendar(y);

        let calendar_table_body = document.querySelector(".calendar_wrapper");
        calendar_table_body.scrollTop = 9 * 100 * 100;

        calendar_table_body.addEventListener('scroll', function (event) {
            if (event.target.scrollTop <= 100 && flag) {
                y--;
                flag = false;
                renderMobileCalendar(y);
                flag = true;
            }
        })
    } else {
        let _month = document.querySelector(".calendar_dropdown .calendar_month_selected");
        let _year = document.querySelector(".calendar_dropdown .calendar_year_selected");
        renderCalendar(_month.dataset.index, _year.dataset.index, _year.dataset.index + '_' + _month.dataset.index, true);
    }

}

//Отнимаем кол-во дней от текущей даты
function quickFilterCalendar(days) {
    let date_begin = document.querySelector(".date_begin");
    let date_end = document.querySelector(".date_end");
    let now = new Date();

    date_end.value = formatDate(now.getFullYear(), now.getMonth() + 1, now.getDate());
    date_end.dataset.timestamp = getTimestamp(now.getFullYear(), now.getMonth(), now.getDate());

    now.setDate(now.getDate() - days);
    date_begin.value = formatDate(now.getFullYear(), now.getMonth() + 1, now.getDate());
    date_begin.dataset.timestamp = getTimestamp(now.getFullYear(), now.getMonth(), now.getDate());

    calendarClearClasses();
    selectDates();
}

function renderMobileCalendar(year) {
    for (let i = 12; i > 0; i--) {
        renderCalendar(i, year, year + '_' + i, false);
    }
}

export function renderCalendar(_month, _year, _ind, _clear) {
    let today = new Date();
    let year = _year;
    let month = _month - 1;
    let first_day = new Date(year, month, 1);
    let first_wday = first_day.getDay();
    if (first_wday === 0) first_wday = 7;

    let oneHour = 1000 * 60 * 60;
    let oneDay = oneHour * 24;
    let prevMonth = new Date(year, month - 1, 10, 10, 0);
    let curMonth = new Date(year, month, 10, 10, 0);
    let prevMonth_last_date = daysInMonth(prevMonth.getMonth() + 1, prevMonth.getFullYear());

    let nextMonth = new Date(year, month + 1, 1, 0, 10, 0);
    let last_date = daysInMonth(curMonth.getMonth() + 1, curMonth.getFullYear());

    let body = document.querySelector(".calendar_dropdown .calendar_table_body");

    let month_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    let month_label = document.createElement("h5");
    month_label.classList.add('mobile_calendar_title')
    month_label.innerHTML = month_names[month] + ' ' + _year;

    let day_table = document.createElement("table");
    day_table.id = "calendar" + _ind;
    day_table.classList = "calendar_table";

    if (_clear) body.innerHTML = " ";
    body.prepend(day_table);
    body.prepend(month_label);

    day_table.innerHTML = "<tr id='weekday'><th>Mo</th><th>Tu</th><th>We</th><th>Th</th><th>Fri</th><th>Sa</th><th>Su</th></tr>" +
        "<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>" +
        "<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>" +
        "<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>" +
        "<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>" +
        "<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>" +
        "<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>" +
        "<tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>";

    let td_d = document.querySelectorAll('#' + day_table.id + " td");

    let date_begin = document.querySelector(".date_begin");
    let date_end = document.querySelector(".date_end");

    if (date_begin.dataset.timestamp === '0' && date_end.dataset.timestamp === '0') {
        date_begin.dataset.timestamp = date_end.dataset.timestamp = getTimestamp(today.getFullYear(), today.getMonth(), today.getDate());
    }

    //Last month
    let last_y = prevMonth.getFullYear();
    let last_m = prevMonth.getMonth() + 1;
    for (let last = 0; last < first_wday - 1; last++) {
        let lastDay = prevMonth_last_date - (first_wday - 1) + last + 1;
        td_d[last].classList.add('no_active');
        td_d[last].innerHTML = lastDay;
        td_d[last].dataset.date = formatDate(last_y, last_m, lastDay);
        td_d[last].dataset.timestamp = getTimestamp(last_y, last_m - 1, lastDay);

        let date2 = new Date(td_d[last].dataset.timestamp * 1000);
        td_d[last].dataset.date2 = date2;
    }

    //Current month
    let today_date = formatDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
    for (let i = 0; i < last_date; i++) {
        if ((i + 1) == first_wday) {
            for (let j = 0; j < last_date; j++) {

                let cur_date = formatDate(year, (month + 1), (j + 1));
                if (today_date === cur_date) {
                    td_d[i + j].classList.add("one");
                }

                td_d[i + j].innerHTML = '<span>' + (1 + j) + '</span>';
                td_d[i + j].dataset.date = cur_date;
                td_d[i + j].dataset.timestamp = getTimestamp(year, (month), (j + 1));

                let date2 = new Date(td_d[i + j].dataset.timestamp * 1000);
                td_d[i + j].dataset.date2 = date2;
            }

        }
    }
    const tds = document.querySelectorAll('#' + day_table.id + ' td');
    for (var i = 0; i < tds.length; i++) {
        tds[i].addEventListener('click', calendarClick);
    }

    //Last days
    if ((first_wday - 1 + last_date) % 7) {
        var next_y = nextMonth.getFullYear();
        var next_m = nextMonth.getMonth() + 1;
        for (let next = 0; next < 7 - (first_wday - 1 + last_date) % 7; next++) {
            td_d[last_date + first_wday - 1 + next].classList.add('no_active');
            td_d[last_date + first_wday - 1 + next].innerHTML = (next + 1);
            td_d[last_date + first_wday - 1 + next].dataset.date = formatDate(next_y, next_m, (next + 1));
            td_d[last_date + first_wday - 1 + next].dataset.timestamp = getTimestamp(next_y, next_m - 1, (next + 1));

            let date2 = new Date(td_d[last_date + first_wday - 1 + next].dataset.timestamp * 1000);
            td_d[last_date + first_wday - 1 + next].dataset.date2 = date2;
        }
    }

    selectDates();
}

function calendarClick(e) {
    let date_begin = document.querySelector(".date_begin");
    let date_end = document.querySelector(".date_end");
    let obj = e.target;

    if (obj.tagName.toLowerCase() === 'span') {
        obj = obj.parentElement;
    }

    //Очистка полей если заново выбирается дата
    if (date_begin.value.trim().length && date_end.value.trim().length) {
        date_begin.value = '';
        date_end.value = '';
        calendarClearClasses();
    }

    //Выделение дат
    if (!date_begin.value.trim().length && !date_end.value.trim().length) {
        date_begin.value = obj.dataset.date;
        date_begin.dataset.timestamp = obj.dataset.timestamp;
        date_end.dataset.timestamp = 0;

        calendarClearClasses();
        obj.classList.add('selected');
        obj.classList.add('start');
        obj.classList.add('end');
    } else if (date_begin.value.trim().length && !date_end.value.trim().length) {
        date_end.value = obj.dataset.date;
        date_end.dataset.timestamp = obj.dataset.timestamp;

        obj.classList.add('selected');
        obj.classList.add('start');
        obj.classList.add('end');
        calendarClearClasses();

        //Если вторая дата меньше первой
        if (date_begin.value > date_end.value) {
            let tmp = date_begin.value;
            date_begin.value = date_end.value;
            date_end.value = tmp;

            tmp = date_begin.dataset.timestamp;
            date_begin.dataset.timestamp = date_end.dataset.timestamp;
            date_end.dataset.timestamp = tmp;
        }

        //Закругляем углы
        let selected = document.querySelector('.selected.start.end[data-timestamp="' + date_begin.dataset.timestamp + '"]');
        if (selected) selected.classList.remove('end');

        selected = document.querySelector('.selected.start.end[data-timestamp="' + date_end.dataset.timestamp + '"]');
        if (selected) selected.classList.remove('start');

        selectDates();
    }

    let choose_period = document.querySelector(".choose_period a");
    if (choose_period)
        triggerEvent(choose_period, 'click');

    e.preventDefault();
}

function calendarClearClasses() {
    let selected = document.querySelectorAll(".calendar_table_body td");
    for (let i = 0; i < selected.length; i++) {
        selected[i].classList.remove('selected');
        selected[i].classList.remove('start');
        selected[i].classList.remove('end');
        selected[i].classList.remove('one');
    }
}

function formatDate(year, month, day) {
    if (month.toString().length < 2) month = '0' + month;
    if (day.toString().length < 2) day = '0' + day;
    return year + '-' + month + '-' + day;
}

function selectDates() {
    let date_begin = document.querySelector(".date_begin");
    let date_end = document.querySelector(".date_end");

    let selected = document.querySelectorAll(".calendar_table_body td");
    for (let i = 0; i < selected.length; i++) {

        if (selected[i].hasAttribute('data-timestamp')) {
            if (selected[i].dataset.timestamp >= date_begin.dataset.timestamp && selected[i].dataset.timestamp <= date_end.dataset.timestamp) {
                selected[i].classList.add('selected')
            }
        }
    }

    let date = new Date(date_begin.dataset.timestamp * 1000);
    let d1 = date.getMonth() + '' + date.getFullYear();
    date.setTime(date_end.dataset.timestamp * 1000);
    let d2 = date.getMonth() + '' + date.getFullYear();

    console.log('d1', d1);
    console.log('d2', d2);

    selected = document.querySelectorAll('td.selected');
    if (selected.length) {
        if (selected[0].dataset.timestamp === date_begin.dataset.timestamp) {
            selected[0].classList.add('start');

            if(d1 === d2){
                if (selected[0].classList.contains('no_active')) {
                    for (let i = 0; i < selected.length; i++) {
                        if (selected[i].classList.contains('no_active')){
                            selected[i].classList.remove('start');
                            selected[i].classList.remove('end');
                            selected[i].classList.remove('selected');
                        }else{
                            selected[i].classList.add('start');
                            break;
                        }
                    }
                }
            }

        }

        if (selected[selected.length - 1].dataset.timestamp === date_end.dataset.timestamp)
            selected[selected.length - 1].classList.add('end');

        if (date_begin.value === date_end.value) {
            //selected[0].classList.add('end');
            selected[selected.length - 1].classList.add('start');
        }
    }
}

function getTimestamp(year, month, day) {
    let newDate = new Date(year, month, day, 10, 0, 0);
    return Math.trunc(newDate.getTime() / 1000).toString();
}

function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
}

function triggerEvent(elem, event) {
    let clickEvent = new Event(event); // Create the event.
    elem.dispatchEvent(clickEvent);    // Dispatch the event.
}