import "../css/main.scss";
import "../css/media.scss";

/* plugins */
import "@babel/polyfill";
import Swiper, { Navigation, Pagination, Lazy, FreeMode, Thumbs } from 'swiper';
import LazyLoad from "vanilla-lazyload";
import Pristine from "pristinejs";
import SimpleScrollbar from 'simple-scrollbar';
import Inputmask from "inputmask";
import fslightbox from "fslightbox";
import ProgressBar from "progressbar.js";
import "./calendar"
import {renderCalendar} from "./calendar"
import Bowser from "bowser";
import Cookie from "js-cookie";


document.addEventListener('DOMContentLoaded', function() {

    //Valid browser test
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isValidBrowser = browser.satisfies({
        yandex: ">16.2",
        safari: ">9.0",
        maxthon: ">4.5",
        ie: ">9",
        opera: ">49",
        firefox: ">67",
        chrome: ">62",
        chromium: ">62",
        seamonkey: ">2.3",
    });

    if(!isValidBrowser) {
        const notSupported = document.querySelector("#browserNotSupported");
        if(notSupported){
            notSupported.classList.add('show');
        }
    }


	Swiper.use([Navigation, Pagination, Lazy, FreeMode, Thumbs]);

	// LAZYLOAD INIT
	var lazyLoadInstance = new LazyLoad({});

	// TOGGLE NAV BTN
	const sandwichBtn = document.querySelector(".toggle_menu");
	if (document.querySelector(".toggle_menu")) {
		sandwichBtn.addEventListener("click", function(e) {
			sandwichBtn.classList.toggle("active");
			document.querySelector(".main_nav").classList.toggle("active");
			document.querySelector("body").classList.toggle("overflow");
		});
	}

	// FIXED NAVBAR
	if(document.querySelector('.main_header')) {
		var header = document.querySelector('.main_header').clientHeight;
		function getScrollTop(){
			if(typeof pageYOffset!= 'undefined'){
				//most browsers except IE before #9
				return pageYOffset;
			}
			else{
				var B= document.body; //IE 'quirks'
				var D= document.documentElement; //IE with doctype
				D= (D.clientHeight)? D: B;
				return D.scrollTop;
			}
		}
		var scrolled = getScrollTop();

		window.addEventListener('resize', function () {
			var header = document.querySelector('.main_header').clientHeight;
		});

		if (scrolled > header) {
			document.querySelector('.main_header').classList.add('fixed_header');
		} else {
			document.querySelector('.main_header').classList.remove('fixed_header');
		}

		document.addEventListener("scroll", function() {
			scrolled = getScrollTop();
			if (scrolled > header) {
				document.querySelector('.main_header').classList.add('fixed_header');
			} else {
				document.querySelector('.main_header').classList.remove('fixed_header');
			}
		});
	}

	// SLIDE DOWN
	let slideDownContent = (target, duration=500) => {
		target.style.removeProperty('display');
		let display = window.getComputedStyle(target).display;
		if (display === 'none') display = 'block';
		target.style.display = display;
		let height = target.offsetHeight;
		target.style.overflow = 'hidden';
		target.style.height = 0;
		target.style.paddingTop = 0;
		target.style.paddingBottom = 0;
		target.style.marginTop = 0;
		target.style.marginBottom = 0;
		target.offsetHeight;
		target.style.boxSizing = 'border-box';
		target.style.transitionProperty = "height, margin, padding";
		target.style.transitionDuration = duration + 'ms';
		target.style.height = height + 'px';
		target.style.removeProperty('padding-top');
		target.style.removeProperty('padding-bottom');
		target.style.removeProperty('margin-top');
		target.style.removeProperty('margin-bottom');
		window.setTimeout( () => {
			target.style.removeProperty('height');
			target.style.removeProperty('overflow');
			target.style.removeProperty('transition-duration');
			target.style.removeProperty('transition-property');
		}, duration);
	}

	// SLIDE UP
	let slideUpContent = (target, duration=500) => {
		target.style.transitionProperty = 'height, margin, padding';
		target.style.transitionDuration = duration + 'ms';
		target.style.boxSizing = 'border-box';
		target.style.height = target.offsetHeight + 'px';
		target.offsetHeight;
		target.style.overflow = 'hidden';
		target.style.height = 0;
		target.style.paddingTop = 0;
		target.style.paddingBottom = 0;
		target.style.marginTop = 0;
		target.style.marginBottom = 0;
		window.setTimeout( () => {
			target.style.display = 'none';
			target.style.removeProperty('height');
			target.style.removeProperty('padding-top');
			target.style.removeProperty('padding-bottom');
			target.style.removeProperty('margin-top');
			target.style.removeProperty('margin-bottom');
			target.style.removeProperty('overflow');
			target.style.removeProperty('transition-duration');
			target.style.removeProperty('transition-property');
			//alert("!");
		}, duration);
	}

	// SLIDE TOGGLE
	var slideToggleContent = (target, duration = 500) => {
		if (window.getComputedStyle(target).display === 'none') {
			return slideDownContent(target, duration);
		} else {
			return slideUpContent(target, duration);
		}
	}

	// TOGGLE USER NAV
	if (document.querySelector('.header_user_menu')) {
		const userContainer = document.querySelector(".header_user_menu");
		const userBtn = document.querySelector(".user_btn");

		userBtn.addEventListener("click", ()=>{
			userContainer.classList.toggle("open");
			slideToggleContent(document.querySelector('.user_nav'), 200);
		});

		document.addEventListener('click', function(event) {
			var isClickInside = userContainer.contains(event.target);
			if (userContainer.classList.contains('open') && !isClickInside) {
				userContainer.classList.remove('open');
				slideUpContent(document.querySelector('.user_nav'), 200);
			}
		})
	}

	// MAIN SLIDER
	if(document.querySelector('.main_section')) {
		var mainSliderSpace = 30;
		if(document.querySelector('.main_section').classList.contains('full_width')) {
			mainSliderSpace = 0;
		}
		var mainSwiper = new Swiper('.main_slider', {
			slidesPerView: 1,
			speed: 800,
			autoHeight: true, //enable auto height
			spaceBetween: 0,
			loop: true,
			lazy: {
				loadPrevNext: true,
				loadPrevNextAmount: 2,
			},
			navigation: {
				nextEl: '.next_main_slide',
				prevEl: '.prev_main_slide',
			},
			pagination: {
				el: '.main_pagination',
				clickable: true,
			},
			breakpoints: {
				768: {
					slidesPerView: 1,
					spaceBetween: mainSliderSpace
				}
			}
		});
	}

	// POPULAR SLIDER
	var popularSlider = document.querySelectorAll('.popular_slider_container');
	for (var i = 0; i < popularSlider.length; i++) {
		var thisSlider = popularSlider[i].querySelector('.popular_slider');
		var thisPrev = popularSlider[i].querySelector('.prev_popular_slide');
		var thisNext = popularSlider[i].querySelector('.next_popular_slide');
		var slider = new Swiper(thisSlider, {
			slidesPerView: "auto",
			spaceBetween: 12,
			loop: false,
			watchSlidesVisibility: true,
			navigation: {
				nextEl: thisNext,
				prevEl: thisPrev,
			},
			lazy: {
				loadPrevNext: true,
				loadPrevNextAmount: 7,
			},
			breakpoints: {
				768: {
					spaceBetween: 15,
				},
				1280: {
					spaceBetween: 32,
				},
			}
		});
	};

	// MODAL
	var showModal;
	var hideModal;

	function modal() {
		if (!Element.prototype.closest) {
			if (!Element.prototype.matches) {
				Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
			}
			Element.prototype.closest = function (s) {
				var el = this;
				var ancestor = this;
				if (!document.documentElement.contains(el)) return null;
				do {
				if (ancestor.matches(s)) return ancestor;
				ancestor = ancestor.parentElement;
				} while (ancestor !== null);
				return null;
			};
		}
		// Settings
		var settings = {
			speedOpen: 50,
			speedClose: 150,
			activeClass: 'is-active',
			visibleClass: 'is-visible',
			selectorTarget: '[data-modal-target]',
			selectorTrigger: '[data-modal-trigger]',
			selectorClose: '[data-modal-close]',
		};
		// Toggle accessibility
		var toggleccessibility = function (event) {
			if (event.getAttribute('aria-expanded') === 'true') {
				event.setAttribute('aria-expanded', false);
			} else {
				event.setAttribute('aria-expanded', true);
			}
		};

		// Open Modal
		var openModal = function (trigger) {
			// Find target
			var target = document.getElementById(trigger.getAttribute('aria-controls'));
			// Make it active
			target.classList.add(settings.activeClass);
			// Make body overflow hidden so it's not scrollable
			document.documentElement.style.overflow = 'hidden';
			// Toggle accessibility
			toggleccessibility(trigger);
			// Make it visible
			setTimeout(function () {
				target.classList.add(settings.visibleClass);
			}, settings.speedOpen);
		};

		// Close Modal
		var closeModal = function (event) {
			// Find target
			var closestParent = event.closest(settings.selectorTarget);
			// Make it not visible
			closestParent.classList.remove(settings.visibleClass);
			// Remove body overflow hidden
			document.documentElement.style.overflow = '';
			// Make it not active
			setTimeout(function () {
				closestParent.classList.remove(settings.activeClass);
			}, settings.speedClose);
		};

		// Click Handler
		var clickHandler = function (event) {
			// Find elements
			var toggle = event.target,
				open = toggle.closest(settings.selectorTrigger),
				close = toggle.closest(settings.selectorClose);
			// Open modal when the open button is clicked
			if (open) {
				openModal(open);
			}
			// Close modal when the close button (or overlay area) is clicked
			if (close) {
				closeModal(close);
			}
			// Prevent default link behavior
			if (open || close) {
				event.preventDefault();
			}
		};

		// Keydown Handler, handle Escape button
		var keydownHandler = function (event) {
			if (event.key === 'Escape' || event.keyCode === 27) {
				// Find all possible modals
				var modals = document.querySelectorAll(settings.selectorTarget),i;
				// Find active modals and close them when escape is clicked
				for (var i = 0; i < modals.length; ++i) {
					if (modals[i].classList.contains(settings.activeClass)) {
						closeModal(modals[i]);
					}
				}
			}
		};

		// show Modal
		showModal = function (modalName) {
			// Find target
			var target = document.getElementById(modalName);
			// Make it active
			target.classList.add(settings.activeClass);
			target.setAttribute('aria-expanded', true);
			// Make body overflow hidden so it's not scrollable
			document.documentElement.style.overflow = 'hidden';
			// Make it visible
			setTimeout(function () {
				target.classList.add(settings.visibleClass);
			}, settings.speedOpen);

		};

		// hide Modal
		hideModal = function (modalName) {
			var target = document.querySelector('.is-active');
			target.classList.remove(settings.activeClass);
			target.setAttribute('aria-expanded', false);
			document.documentElement.style.overflow = '';
			setTimeout(function () {
				target.classList.remove(settings.visibleClass);
			}, settings.speedClose);
		};

		// Inits & Event Listeners
		document.addEventListener('click', clickHandler, false);
		document.addEventListener('keydown', keydownHandler, false);

	};
	modal();

	// DROPDOWN ITEM
	var dropdownItems = document.querySelectorAll('.dropdown_item');
	for (var i = 0; i < dropdownItems.length; i++) {
		var dropBtn = dropdownItems[i].querySelector('.dropdown_btn');
		dropBtn.addEventListener('click', function () {
			this.closest('.dropdown_item').classList.toggle('open');
		});
		var dropListLinks = dropdownItems[i].querySelectorAll('.dropdown_list a');
		for (var a = 0; a < dropListLinks.length; a++) {
			dropListLinks[a].addEventListener('click', function (e) {
				var currentLink = this.innerHTML;
				var thisBtn = this.closest('.dropdown_item').querySelector('.dropdown_btn');
				thisBtn.innerHTML = currentLink;
				this.closest('.dropdown_item').classList.toggle('open');
				e.preventDefault();
			});
		}
	}

	document.addEventListener('click', function (event) {
		for (var i = 0; i < dropdownItems.length; i++) {
			var isClickInside = dropdownItems[i].contains(event.target);
			if (dropdownItems[i].classList.contains('open') && !isClickInside) {
				dropdownItems[i].classList.remove('open');
			}
		}
	});

	// COOKIES HIDE
	var cookieBtn = document.querySelector(".cookiew_window .primary_btn");
	if (document.querySelector(".cookiew_window")) {
		cookieBtn.addEventListener('click', function (e) {
			document.querySelector(".cookiew_window").classList.remove('active');
            Cookie.set('accept-cookie', 'yes');
		});
	}

    if(Cookie.get('accept-cookie') != 'yes') {
        setTimeout( function(){
            document.querySelector('.cookiew_window').classList.add('active');
        }, 500);
    }

	// CATEGORIES SLIDER
	window.addEventListener('load', () => {
		var categoriesSwiper = new Swiper('.category_slider', {
			slidesPerView: "auto",
			speed: 400,
			spaceBetween: 1,
			freeMode: true,
			loop: false,
			navigation: {
				nextEl: '.next_category_slide',
				prevEl: '.prev_category_slide',
			}
		});
	}, false);

	// SORT DROPDOWN
	var sortDropdownItems = document.querySelectorAll('.sort_dropdown');
	for (var i = 0; i < sortDropdownItems.length; i++) {
		var dropBtn = sortDropdownItems[i].querySelector('.sort_btn');
		dropBtn.addEventListener('click', function () {
			this.closest('.sort_dropdown').classList.toggle('open');
		});
		var dropListLinks = sortDropdownItems[i].querySelectorAll('.dropdown_list a');
		for (var a = 0; a < dropListLinks.length; a++) {
			dropListLinks[a].addEventListener('click', function (e) {
				this.closest('.sort_dropdown').classList.add('selected');
				var currentLink = this.innerHTML;
				var allLi = this.closest('.sort_dropdown').querySelectorAll('li');
				for (var x = 0; x < allLi.length; x++) {
					allLi[x].classList.remove('active');
				};
				this.closest('li').classList.add('active');
				var thisBtn = this.closest('.sort_dropdown').querySelector('.sort_btn');
				thisBtn.innerHTML = currentLink;

				if (thisBtn.hasAttribute("data-index") && this.hasAttribute('data-index')) {
					thisBtn.dataset.index = this.dataset.index;
					let _month = document.querySelector(".calendar_dropdown .calendar_month_selected");
					let _year = document.querySelector(".calendar_dropdown .calendar_year_selected");
					renderCalendar(_month.dataset.index, _year.dataset.index, _year.dataset.index + '_' + _month.dataset.index, true);
				}


				this.closest('.sort_dropdown').classList.toggle('open');
				e.preventDefault();
			});
		}
		var mobileClose = sortDropdownItems[i].querySelector('.mobile_drop_close');
		mobileClose.addEventListener('click', function (e) {
			this.closest('.sort_dropdown').classList.remove('open');
		});
	}

	document.addEventListener('click', function (event) {
		for (var i = 0; i < sortDropdownItems.length; i++) {
			var isClickInside = sortDropdownItems[i].contains(event.target);
			if (sortDropdownItems[i].classList.contains('open') && !isClickInside) {
				sortDropdownItems[i].classList.remove('open');
			}
		}
	});

	// PERKS SLIDER
	var perksSlider = new Swiper('.perks_slider', {
		slidesPerView: "auto",
		spaceBetween: 12,
		loop: false,
		watchSlidesVisibility: true,
		lazy: {
			loadPrevNext: true,
			loadPrevNextAmount: 5,
		},
	});

	// CARD SLIDER
	var cardThumbs = new Swiper('.slider_thumbnails', {
		loop: true,
		slidesPerView: 4,
		spaceBetween: 8,
		watchSlidesProgress: true,
		lazy: {
			loadPrevNext: true,
			loadPrevNextAmount: 5,
		},
	});

	var cardSlider = new Swiper('.slider_gallery', {
		loop: true,
		spaceBetween: 0,
		lazy: {
			loadPrevNext: true,
			loadPrevNextAmount: 5,
		},
		navigation: {
			nextEl: '.thumb-next',
			prevEl: '.thumb-prev',
		},
		pagination: {
			el: '.card_pagination',
			clickable: true,
		},
		thumbs: {
			swiper: cardThumbs,
		},
	});

	// LEFT SIDEBAR SHOW / HIDE
	if (document.querySelector(".left_nav_sidebar")) {
		document.querySelector(".mobile_nav_btn").addEventListener('click', function (e) {
			document.querySelector(".left_nav_sidebar").classList.add('active');
			document.querySelector("body").classList.add("overflow");
		});
		document.querySelector(".left_nav_sidebar .mobile_close_btn").addEventListener('click', function (e) {
			document.querySelector(".left_nav_sidebar").classList.remove('active');
			document.querySelector("body").classList.remove("overflow");
		});
	}

	// FORM INPUT DROPDOWN
	var formDropdownItems = document.querySelectorAll('.form-control-with-dropdown');
	for (var i = 0; i < formDropdownItems.length; i++) {
		var thisInputValue =  formDropdownItems[i];
		var dropList = formDropdownItems[i].closest('.form-group').querySelector('.form-control-dropdown');
		var dropListLinks = formDropdownItems[i].closest('.form-group').querySelectorAll('.form-control-dropdown li');

		formDropdownItems[i].addEventListener('keyup', function() {
			var filter = this.value.toLowerCase();
			for (var a = 0; a < dropListLinks.length; a++) {
				var txtValue = dropListLinks[a].innerText.toLowerCase();
				if (txtValue.indexOf(filter) > -1) {
					dropListLinks[a].style.display = "";
				} else {
					dropListLinks[a].style.display = "none";
				}
				if (filter.length) {
					dropList.classList.add('open');
				} else {
					dropList.classList.remove('open');
				}
			}
  		});
		for (var a = 0; a < dropListLinks.length; a++) {
			dropListLinks[a].addEventListener('click', function (e) {
				var currentLink = this.innerHTML;
				thisInputValue.value = currentLink;
				dropList.classList.remove('open');
				e.preventDefault();
			});
		}
	}

	document.addEventListener('click', function (event) {
		for (var i = 0; i < formDropdownItems.length; i++) {
			var isClickInside = formDropdownItems[i].closest('.form-group').contains(event.target);
			var thisList = formDropdownItems[i].closest('.form-group').querySelector('.form-control-dropdown');
			if (thisList.classList.contains('open') && !isClickInside) {
				thisList.classList.remove('open');
			}
		}
	});

	// PASSWORD TYPE TOGGLE
	var togglePassBtn = document.querySelectorAll(".password_type_change");
	for (var i = 0; i < togglePassBtn.length; i++) {
		togglePassBtn[i].addEventListener('click', function (e) {
			this.classList.toggle('active');
			var thisInput;
			for (let sibling of this.parentNode.children) {
				if (sibling !== this && sibling.nodeName == "INPUT" ) {
					thisInput = sibling;
				}
			}
			if (thisInput.type == 'password') {
				thisInput.setAttribute('type','text');
			} else {
				thisInput.setAttribute('type','password');
			}
		});
	}

	// MOBILE PROFILE MODALS
	if (document.querySelector(".profile_nav .accounts_link")) {
		document.querySelector(".profile_nav .accounts_link").addEventListener('click', function (e) {
			e.preventDefault();
			document.querySelector(".profile_section .accounts_wrapper").classList.add('active');
		});
		document.querySelector(".profile_section .accounts_wrapper .title").addEventListener('click', function (e) {
			e.preventDefault();
			document.querySelector(".profile_section .accounts_wrapper").classList.remove('active');
		});
	}
	if (document.querySelector(".profile_nav .shipping_link")) {
		document.querySelector(".profile_nav .shipping_link").addEventListener('click', function (e) {
			e.preventDefault();
			document.querySelector(".profile_section .shipping_wrapper").classList.add('active');
		});
		document.querySelector(".profile_section .shipping_wrapper .title").addEventListener('click', function (e) {
			e.preventDefault();
			document.querySelector(".profile_section .shipping_wrapper").classList.remove('active');
		});
	}
	if (document.querySelector(".profile_nav .bio_link")) {
		document.querySelector(".profile_nav .bio_link").addEventListener('click', function (e) {
			e.preventDefault();
			document.querySelector(".profile_section .bio_wrapper").classList.add('active');
		});
		document.querySelector(".profile_section .bio_wrapper .mobile_close").addEventListener('click', function (e) {
			e.preventDefault();
			document.querySelector(".profile_section .bio_wrapper").classList.remove('active');
		});
	}

	// CLOSE MODAL WITH back_mobile Class
	var backMobileTitle = document.querySelectorAll(".back_mobile .modal_title");
	if (document.querySelector(".back_mobile")) {
		for (var i = 0; i < backMobileTitle.length; i++) {
			backMobileTitle[i].addEventListener('click', function (e) {
				hideModal('modal');
			});
		}
	}

	// RED DEL BTN
	var redDelBtn = document.querySelectorAll(".edit_form .red_error");
	for (var i = 0; i < redDelBtn.length; i++) {
		redDelBtn[i].addEventListener('click', function (e) {
			hideModal('modal');
			showModal('confirm_del_modal');
		});
	}

	// CARD BUY BUTTON FIXED
	if (document.querySelector('.project_card .card_info .btn_block')) {
		var fixedBuyBtn = document.querySelector('.popular_projects');
		window.addEventListener('scroll', function() {
			var rect = fixedBuyBtn.getBoundingClientRect();
			var winHeight = window.innerHeight;
			if (rect.top - winHeight < 0) {
				document.querySelector('.project_card .card_info .btn_block').classList.add('active');
			} else {
				document.querySelector('.project_card .card_info .btn_block').classList.remove('active');
			}
		})
	}

	// CHANGE ACTIVE CATEGORY
	var activeCategoryBtn = document.querySelectorAll(".category_slider a");
	var modalActiveCategoryBtn = document.querySelectorAll(".categories_modal .category_list a");
	for (var i = 0; i < activeCategoryBtn.length; i++) {
		activeCategoryBtn[i].addEventListener('click', function (e) {
			for (var a = 0; a < activeCategoryBtn.length; a++) {
				activeCategoryBtn[a].classList.remove('active');
			}
			this.classList.add('active');
			var thisCat = this.getAttribute('data-val');

			for (var a = 0; a < modalActiveCategoryBtn.length; a++) {
				modalActiveCategoryBtn[a].closest('li').classList.remove('active');
				if (modalActiveCategoryBtn[a].getAttribute('data-val') == thisCat) {
					modalActiveCategoryBtn[a].closest('li').classList.add('active');
				}
			}
			e.preventDefault();
		});
	}
	for (var i = 0; i < modalActiveCategoryBtn.length; i++) {
		modalActiveCategoryBtn[i].addEventListener('click', function (e) {
			for (var a = 0; a < modalActiveCategoryBtn.length; a++) {
				modalActiveCategoryBtn[a].closest('li').classList.remove('active');
			}
			this.closest('li').classList.add('active');
			var thisCat = this.getAttribute('data-val');

			for (var a = 0; a < activeCategoryBtn.length; a++) {
				activeCategoryBtn[a].classList.remove('active');
				if (activeCategoryBtn[a].getAttribute('data-val') == thisCat) {
					activeCategoryBtn[a].classList.add('active');
				}
			}
			e.preventDefault();
		});
	}


	if (document.querySelector('.actor_profile_nav')) {
		var condition = true;

		var toggleNavBtn = function() {
			var allProfileNav = document.querySelectorAll('.actor_profile_nav li');
			var navList = [];
			for (var i = 0; i < allProfileNav.length; i++) {
				navList.push(allProfileNav[i]);
			}
			var reversList = navList.reverse();
			if (condition == true) {
				for (var i = 0; i < reversList.length; i++) {
					if (i > 0 && i < 4) {
						reversList[i].style.display = "none";
					}
				}
				condition = false;
			} else {
				for (var i = 0; i < reversList.length; i++) {
					if (i > 0 && i < 4) {
						reversList[i].style.display = "";
					}
				}
				condition = true;
			}
			console.log(condition);
			return condition;
		}
		toggleNavBtn();
		document.querySelector('.actor_profile_nav .menu_btn').addEventListener('click', function(e) {
			this.classList.toggle('active');
			toggleNavBtn();
		})
	}



	// CONTEXT MENU
	if (document.querySelector('.with_context_menu')) {
		(function() {

		"use strict";

		/**
		 * Function to check if we clicked inside an element with a particular class
		 * name.
		 *
		 * @param {Object} e The event
		 * @param {String} className The class name to check against
		 * @return {Boolean}
		 */
		function clickInsideElement( e, className ) {
			var el = e.srcElement || e.target;

			if ( el.classList.contains(className) ) {
			return el;
			} else {
			while ( el = el.parentNode ) {
				if ( el.classList && el.classList.contains(className) ) {
				return el;
				}
			}
			}

			return false;
		}

		/**
		 * Get's exact position of event.
		 *
		 * @param {Object} e The event passed in
		 * @return {Object} Returns the x and y position
		 */
		function getPosition(e) {
			var posx = 0;
			var posy = 0;

			if (!e) var e = window.event;

			if (e.pageX || e.pageY) {
				posx = e.pageX;
				posy = e.pageY;
			} else if (e.clientX || e.clientY) {
				posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
				posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
			}

			return {
			x: posx,
			y: posy
			}
		}

		/**
		 * Variables.
		 */
		var contextMenuClassName = "context-menu";
		var contextMenuItemClassName = "context-menu__item";
		var contextMenuLinkClassName = "context-menu__link";
		var contextMenuActive = "context-menu--active";

		var taskItemClassName = "task";
		var taskItemInContext;

		var clickCoords;
		var clickCoordsX;
		var clickCoordsY;

		var menu = document.querySelector("#context-menu");
		var menuItems = menu.querySelectorAll(".context-menu__item");
		var menuState = 0;
		var menuWidth;
		var menuHeight;
		var menuPosition;
		var menuPositionX;
		var menuPositionY;

		var windowWidth;
		var windowHeight;

		/**
		 * Initialise our application's code.
		 */
		function init() {
			contextListener();
			clickListener();
			keyupListener();
			resizeListener();
		}

		/**
		 * Listens for contextmenu events.
		 */
		function contextListener() {
			document.addEventListener( "contextmenu", function(e) {
			taskItemInContext = clickInsideElement( e, taskItemClassName );

			if ( taskItemInContext ) {
				e.preventDefault();
				toggleMenuOn();
				positionMenu(e);
				if (taskItemInContext.classList.contains('tertiary_btn')) {
					document.querySelector('.context-menu .del_name').textContent = "Delete folder";
				} else {
					document.querySelector('.context-menu .del_name').textContent = "Delete file";
				}
			} else {
				taskItemInContext = null;
				toggleMenuOff();
			}
			});
		}

		/**
		 * Listens for click events.
		 */
		function clickListener() {
			document.addEventListener( "click", function(e) {
			var clickeElIsLink = clickInsideElement( e, contextMenuLinkClassName );

			if ( clickeElIsLink ) {
				e.preventDefault();
				menuItemListener( clickeElIsLink );

			} else {
				var button = e.which || e.button;
				if ( button === 1 ) {
					toggleMenuOff();
				}
			}
			});
		}

		/**
		 * Listens for keyup events.
		 */
		function keyupListener() {
			window.onkeyup = function(e) {
				if ( e.keyCode === 27 ) {
					toggleMenuOff();
				}
			}
		}

		/**
		 * Window resize event listener
		 */
		function resizeListener() {
			window.onresize = function(e) {
			toggleMenuOff();
			};
		}

		/**
		 * Turns the custom context menu on.
		 */
		function toggleMenuOn() {
			if ( menuState !== 1 ) {
			menuState = 1;
			menu.classList.add( contextMenuActive );
			}
		}

		/**
		 * Turns the custom context menu off.
		 */
		function toggleMenuOff() {
			if ( menuState !== 0 ) {
			menuState = 0;
			menu.classList.remove( contextMenuActive );
			}
		}

		/**
		 * Positions the menu properly.
		 *
		 * @param {Object} e The event
		 */
		function positionMenu(e) {
			clickCoords = getPosition(e);
			clickCoordsX = clickCoords.x;
			clickCoordsY = clickCoords.y;

			menuWidth = menu.offsetWidth + 4;
			menuHeight = menu.offsetHeight + 4;

			windowWidth = document.innerWidth;
			windowHeight = document.innerHeight;

			if ( (windowWidth - clickCoordsX) < menuWidth ) {
				menu.style.left = windowWidth - menuWidth + "px";
			} else {
				menu.style.left = clickCoordsX + "px";
			}

			if ( (windowHeight - clickCoordsY) < menuHeight ) {
				menu.style.top = windowHeight - menuHeight + "px";
			} else {
				menu.style.top = clickCoordsY + "px";
			}
		}

		/**
		 * Dummy action function that logs an action when a menu item link is clicked
		 *
		 * @param {HTMLElement} link The link that was clicked
		 */
		function menuItemListener( link ) {
			console.log( "Task ID - " + taskItemInContext.getAttribute("data-id") + ", Task action - " + link.getAttribute("data-action"));
			toggleMenuOff();
		}

		/**
		 * Run the app.
		 */
		init();

		var mobileDotsBtn = document.querySelectorAll(".profile_section .folders_wrapper .button_wrap .mobile-dot");
		for (var i = 0; i < mobileDotsBtn.length; i++) {
			mobileDotsBtn[i].addEventListener('click', function (e) {
				document.querySelector('.context-menu').classList.add('context-menu--active')
			});
		}
		document.querySelector(".context-menu .mobile_close").addEventListener('click', function (e) {
			document.querySelector('.context-menu').classList.remove('context-menu--active')
		})

		})();
	};





	if (document.querySelector('.modal_scroll_block')) {
		var modalScrollbar2 = document.querySelectorAll('.modal_scroll_block');
		for (var i = 0; i < modalScrollbar2.length; i++) {
			SimpleScrollbar.initEl(modalScrollbar2[i]);
		}

	}

	// CIRCLE
	if (document.querySelector('.circle')) {
		var resizeWidth;
		if (window.innerWidth > 767) {
			resizeWidth = 6;
		} else {
			resizeWidth = 3;
		}
		var circleWidth = document.querySelector('.circle').getAttribute('data-width');
		var circleBar = new ProgressBar.Circle('.circle', {
			color: '#6834D1',
			trailColor: 'rgba(135, 141, 167, 0.36)',
			strokeWidth: resizeWidth,
			trailWidth: resizeWidth,
			easing: 'easeInOut',
			duration: 1400,
			text: {
				autoStyleContainer: false
			},
			from: { color: '#6834D1', width: resizeWidth },
			to: { color: '#6834D1', width: resizeWidth },
			// Set default step function for all animate calls
			step: function(state, circle) {
				circle.path.setAttribute('stroke', state.color);
				circle.path.setAttribute('stroke-width', state.width);
				var value = Math.round(circle.value() * 100) + '%';
				if (value === 0) {
					circle.setText('');
				} else {
					circle.setText(value);
				}

			}
		});
		circleBar.animate(circleWidth / 100);
	}

	if (document.querySelector('.project_stats')) {
		document.querySelector('.project_stats .see_all').addEventListener('click', function (e) {
			document.querySelector('.project_stats_full_window').classList.toggle('active');
		});
		document.querySelector('.project_stats_full_window .close').addEventListener('click', function (e) {
			document.querySelector('.project_stats_full_window').classList.remove('active');
		});
	}


	// CALENDAR DROPDOWN
	if (document.querySelector('.calendar_dropdown')) {

		var calendarDropdownItem = document.querySelector('.calendar_dropdown .calendar_btn');

		calendarDropdownItem.addEventListener('click', function () {
			this.closest('.calendar_dropdown').classList.toggle('open');
		});
		var calendarListLinks = document.querySelectorAll('.calendar_dropdown .drop_list a');
		for (var a = 0; a < calendarListLinks.length; a++) {
			calendarListLinks[a].addEventListener('click', function (e) {
				var currentLink = this.innerHTML;
				var allLi = this.closest('ul').querySelectorAll('li');
				for (var x = 0; x < allLi.length; x++) {
					allLi[x].classList.remove('active');
				};
				this.closest('li').classList.add('active');
				var thisBtn = this.closest('.calendar_dropdown').querySelector('.calendar_btn');

				if(this.closest('li').classList.contains('choose_period')){
					let date_begin = document.querySelector(".date_begin");
					let date_end = document.querySelector(".date_end");

					if(date_end.dataset.timestamp === '0' ) date_end.dataset.timestamp = date_begin.dataset.timestamp;

					let date_begin_str = new Date(date_begin.dataset.timestamp * 1000);
					date_begin_str = date_begin_str.toLocaleDateString('en-us', { day:"numeric", year:"numeric", month:"short"})

					let date_end_str = new Date(date_end.dataset.timestamp * 1000);
					date_end_str = date_end_str.toLocaleDateString('en-us', { day:"numeric", year:"numeric", month:"short"})

					if(date_begin_str === date_end_str)
					    thisBtn.innerHTML = date_begin_str;
					else
						thisBtn.innerHTML = date_begin_str + ' - ' + date_end_str;
				} else {
					thisBtn.innerHTML = currentLink;
					let period = this.closest('li').dataset.period;

					switch (period){
						case 'w':

							break;
					}
				}

				e.preventDefault();
				if (e.target.closest('li').classList.contains('choose_period')) {
					document.querySelector('.calendar_dropdown .calendar_wrapper').classList.add('active');
				} else {
					document.querySelector('.calendar_dropdown .calendar_wrapper').classList.remove('active');
				}
			});
		}

		document.querySelector('.cancel_btn').addEventListener('click', function () {
			this.closest('.calendar_dropdown').classList.remove('open');
		});
		document.querySelector('.update_btn').addEventListener('click', function () {
			this.closest('.calendar_dropdown').classList.remove('open');

			// let date_begin = document.querySelector(".date_begin");
			// let date_end = document.querySelector(".date_end");
			//
			// console.log('date_end.dataset.timestamp', date_end.dataset.timestamp);
			// if(date_end.dataset.timestamp === '0' ) date_end.dataset.timestamp = date_begin.dataset.timestamp;
			//
			// let date_begin_str = new Date(date_begin.dataset.timestamp * 1000);
			// date_begin_str = date_begin_str.toLocaleDateString('en-us', { day:"numeric", year:"numeric", month:"short"})
			//
			// let date_end_str = new Date(date_end.dataset.timestamp * 1000);
			// date_end_str = date_end_str.toLocaleDateString('en-us', { day:"numeric", year:"numeric", month:"short"})
			//
			// console.log('date_begin_str', date_begin_str);
			// console.log('date_end_str', date_end_str);
		});
		document.querySelector('.calendar_dropdown .drop_list .mobile_close').addEventListener('click', function () {
			this.closest('.calendar_dropdown').classList.remove('open');
		});
		document.querySelector('.profile_section .mobile_calendar').addEventListener('click', function () {
			document.querySelector('.calendar_dropdown').classList.add('open');
		});
		document.querySelector('.calendar_wrapper .mobile_back').addEventListener('click', function () {
			document.querySelector('.calendar_dropdown .calendar_wrapper').classList.remove('active');
		});



		document.addEventListener('click', function (event) {
			if (window.innerWidth > 767) {
				var isClickInsideCalendar = calendarDropdownItem.closest('.calendar_dropdown').contains(event.target);
				if (calendarDropdownItem.closest('.calendar_dropdown').classList.contains('open') && !isClickInsideCalendar) {
					calendarDropdownItem.closest('.calendar_dropdown').classList.remove('open');
				}
			}
		});
	}



	if (document.querySelector('.to_top')) {

		var toTop = document.querySelector('.to_top');

		function checkPosition() {
			var windowY = window.scrollY;
			if (windowY > 300) {
				toTop.classList.add('active');
			} else {
				toTop.classList.remove('active');
			}
		}
		checkPosition();

		window.addEventListener('scroll', checkPosition);

		toTop.addEventListener('click', function () {
			window.scroll({top: 0, left: 0, behavior: 'smooth'});
		});
	}





	// FORMS VALINADION AND SEND
	if (document.querySelector('.main_form')) {
		var mainForm = document.querySelector('.main_form');
		var mainFormPristine = new Pristine(mainForm);
		mainForm.addEventListener('submit', function (e) {
			var valid = mainFormPristine.validate();
			if (valid === true) {
				return true;
			} else {
				e.preventDefault();
			}
		});
	}

	// SUBSCRIBE FORM
	if (document.querySelector('.subscribe_form')) {
		var subscribeForm = document.querySelector('.subscribe_form');
		var subscribeFormPristine = new Pristine(subscribeForm);
		subscribeForm.addEventListener('submit', function (e) {
			var valid = subscribeFormPristine.validate();
			if (valid === true) {
				e.preventDefault();
				var data = {};
				for (var i = 0, ii = this.length; i < ii; ++i) {
					var input = this[i];
					if (input.name) {
						data[input.name] = input.value;
					}
					var xhr = new XMLHttpRequest();
					xhr.open('POST', 'url', true);
					xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
					xhr.onreadystatechange = function() {
						if (xhr.readyState != 4) return;
						if (xhr.status != 200) {
							showModal('ok_modal');
							subscribeForm.reset();
						} else {
							console.log(xhr.response);
						}
					}
					xhr.send(JSON.stringify(data));
				}
			} else {
				e.preventDefault();
			}
		});
	}

	// TERMS FORM
	if (document.querySelector('.terms_form')) {
		var termsForm = document.querySelector('.terms_form');
		var termsFormPristine = new Pristine(termsForm);
		termsForm.addEventListener('submit', function (e) {
			var valid = termsFormPristine.validate();
			if (valid === true) {
				e.preventDefault();
				var data = {};
				for (var i = 0, ii = this.length; i < ii; ++i) {
					var input = this[i];
					if (input.name) {
						data[input.name] = input.value;
					}
					var xhr = new XMLHttpRequest();
					xhr.open('POST', 'url', true);
					xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
					xhr.onreadystatechange = function() {
						if (xhr.readyState != 4) return;
						if (xhr.status != 200) {
							console.log('ok');
							hideModal('terms_modal');
						} else {
							console.log(xhr.response);
						}
					}
					xhr.send(JSON.stringify(data));
				}
			} else {
				e.preventDefault();
			}
		});
	}

	// PAYMENT FORM
	if (document.querySelector('.payment_form')) {
		var paymentForm = document.querySelector('.payment_form');
		var paymentFormPristine = new Pristine(paymentForm);
		var currentVal = 1;
		var allSteps = 0;
		for (var i = 0; i < document.querySelectorAll('.payment_form .step').length; i++) {
			var numerofstep = i + 1;
			allSteps = numerofstep;
		}
		document.querySelector('.mobile_step_header .all_steps').innerHTML = allSteps;

		Inputmask({"mask": "9999 9999 9999 9999"}).mask(document.querySelector('.payment_form input[name="cardnumber"]'));
		Inputmask({"mask": "99/99"}).mask(document.querySelector('.payment_form input[name="carddate"]'));
		Inputmask({"mask": "999"}).mask(document.querySelector('.payment_form input[name="cvc"]'));

		var continueBtn = document.querySelectorAll('.payment_form .continue_btn');
		for (var i = 0; i < continueBtn.length; i++) {
			continueBtn[i].addEventListener('click', function (e) {
				var currentStep = this.closest('.step');
				var currentStepPristine = new Pristine(currentStep);
				var valid = currentStepPristine.validate();

				if (valid === true) {
					currentVal++;
					document.querySelector('.mobile_step_header .current_step').innerHTML = currentVal;
					e.preventDefault();
					if (document.querySelector('.step_1').classList.contains('active') && document.querySelector('.step_2')) {
						document.querySelector('.step_1').classList.remove('active');
						document.querySelector('.step_2').classList.add('active');
					} else if (document.querySelector('.step_1').classList.contains('active')) {
						document.querySelector('.step_1').classList.remove('active');
						document.querySelector('.step_3').classList.add('active');
					} else if (document.querySelector('.step_2') && document.querySelector('.step_2').classList.contains('active')) {
						document.querySelector('.step_2').classList.remove('active');
						document.querySelector('.step_3').classList.add('active');
					} else if (document.querySelector('.step_3').classList.contains('active')) {
						document.querySelector('.step_3').classList.remove('active');
						document.querySelector('.step_4').classList.add('active');
					} else {

					}
				} else {
					e.preventDefault();
				}
			});
		}

		paymentForm.addEventListener('submit', function (e) {
			var valid = paymentFormPristine.validate();
			if (valid === true) {
				return true;
			} else {
				e.preventDefault();
			}
		});
	}

	// FAQ CONTACTS FORM
	if (document.querySelector('.contact_form')) {
		var termsForm = document.querySelector('.contact_form');
		var termsFormPristine = new Pristine(termsForm);
		termsForm.addEventListener('submit', function (e) {
			var valid = termsFormPristine.validate();
			if (valid === true) {
				e.preventDefault();
				var data = {};
				for (var i = 0, ii = this.length; i < ii; ++i) {
					var input = this[i];
					if (input.name) {
						data[input.name] = input.value;
					}
					var xhr = new XMLHttpRequest();
					xhr.open('POST', 'url', true);
					xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
					xhr.onreadystatechange = function() {
						if (xhr.readyState != 4) return;
						if (xhr.status != 200) {
							showModal('thanks_modal');
						} else {
							console.log(xhr.response);
						}
					}
					xhr.send(JSON.stringify(data));
				}
			} else {
				e.preventDefault();
			}
		});
	}

	// SIGN IN / UP FORMS
	var signUpForm = document.querySelectorAll('.sign_up_form');
	if (document.querySelector('.sign_up_form')) {
		for (let index = 0; index < signUpForm.length; ++index) {
			const signUpPristine = new Pristine(signUpForm[index]);
			signUpForm[index].addEventListener('submit', function (e) {
				var valid = signUpPristine.validate();
				if (signUpForm[index].id == 'forgot_password_form') {
					if (valid === true) {
						e.preventDefault();
						document.location.href="check_your_email.html";
						return true;
					} else {
						e.preventDefault();
					}
				} else {
					if (valid === true) {
						return true;
					} else {
						e.preventDefault();
					}
				}
			});
		}
	}

	const fileInput = document.getElementById('file_input');
	const imgPreview = document.querySelector(".edit_form .no_movie");
	let dropbox = document.querySelector(".file_drag_label");
	const handleFiles = () => {
	const selectedFiles = [...fileInput.files];
		console.log(selectedFiles);
		getImgData();
	}
	if (document.getElementById('file_input')) {

		fileInput.addEventListener("change", handleFiles);

		const dragenter = (e) => {
			e.stopPropagation();
			e.preventDefault();
			getImgData();
		}
		const dragover = (e) => {
			e.stopPropagation();
			e.preventDefault();
			getImgData();
		}
		const drop = (e) => {
			e.stopPropagation();
			e.preventDefault();
			const dt = e.dataTransfer;
			const files = [...dt.files];
			getImgData();
		}
		dropbox.addEventListener("dragenter", dragenter, false);
		dropbox.addEventListener("dragover", dragover, false);
		dropbox.addEventListener("drop", drop, false);
	}




	function getImgData() {
		const filesImg = fileInput.files[0];
		if (filesImg) {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(filesImg);
			fileReader.addEventListener("load", function () {
				imgPreview.style.display = "block";
				imgPreview.innerHTML = '<img src="' + this.result + '" />';
			});
		}
	}

	// EDIT FORMS
	var editForm = document.querySelectorAll('.edit_form');

	if (document.querySelector('.edit_form')) {
		for (var index = 0; index < editForm.length; ++index) {
			const editPristine = new Pristine(editForm[index]);

			editForm[index].addEventListener('submit', function (e) {

				var valid = editPristine.validate();
				if (valid === true) {
					e.preventDefault();
					if (document.querySelector('.file_drag_label')) {
						document.querySelector('.file_drag_label').classList.remove('has-danger')
					};
					var data = {};
					for (var i = 0, ii = this.length; i < ii; ++i) {
						var input = this[i];
						if (input.name) {
							data[input.name] = input.value;
						}
						var xhr = new XMLHttpRequest();
						xhr.open('POST', 'url', true);
						xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
						xhr.onreadystatechange = function() {
							if (xhr.readyState != 4) return;
							if (xhr.status != 200) {
								hideModal('modal')
								setTimeout(() => {
									showModal('ok_modal');
								}, 300);
								e.target.reset();
							} else {
								console.log(xhr.response);
							}
						}
						xhr.send(JSON.stringify(data));
					}
					return true;

				} else {
					e.preventDefault();
					if (document.querySelector('.file_drag_label')) {
						document.querySelector('.file_drag_label').classList.add('has-danger')
					};
				}
			});
		}
	}



	// TERM MODAL SHOW
	if (document.querySelector('#terms_modal')) {
		setTimeout( function(){
			showModal('terms_modal');
			// SIMPLE SCROLLBAR
			var modalScrollbar = document.querySelector('.modal_scroll_block');
			if (document.querySelector('.modal_scroll_block')) {
				SimpleScrollbar.initEl(modalScrollbar);
			}
		}, 100);
	}

});


